import { useState } from "react"

export default function ActionSheet(props){

 const {open} =props
 const [openSheet,setSheetOpen]=useState(open)
    return(
<>
       
<div class="fixed z-50 overflow-y-auto top-0 w-full left-0 " id="modal">
  <div class="flex  min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-900 opacity-75" />
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
    <div class="fixed h-48 bottom-10 my-auto align-center bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:mx-auto" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div class="bg-white px-4 pt-5 pb-4  sm:p-6 sm:pb-4">
        <label>Name</label>
        <input type="text" class="w-full bg-gray-100 p-2 mt-2 mb-3" />
        <label>Url</label>
        <input type="text" class="w-full bg-gray-100 p-2 mt-2 mb-3" />
        <label>Name</label>
        <input type="text" class="w-full bg-gray-100 p-2 mt-2 mb-3" />
        <label>Url</label>
        <input type="text" class="w-full bg-gray-100 p-2 mt-2 mb-3" />
      </div>
      <div class="bg-gray-200 px-4 py-3 text-right">
        <button type="button" class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2" onclick="toggleModal()"><i class="fas fa-times"></i> Cancel</button>
        <button type="button" class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"><i class="fas fa-plus"></i> Create</button>
      </div>
    </div>
  </div>
</div>
</>
    )
}