 import { ReactComponent as Cbean } from "./cbean.svg";

 export default function Raiting(props){
    const {height,className,value,maxvalue}=props;

    const showRaiting = ()=>{
        let rows=[]
        for(let i=1;i <= value ; i++){
           rows.push( <Cbean key={i} height={height}className={className+' mt-1'}/>)
        }
        for(let i=1;i <= maxvalue-value; i++){
           rows.push( <Cbean key={10+i} height={height}className="fill-gray-400 mt-1"/>)
        }
        return rows;
    }
    return(
        <div className="flex flex-row">
             
        {showRaiting()
        }    
        
        </div>
    )

 }