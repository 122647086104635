import { Dialog, DialogBody, DialogFooter, DialogHeader,Button,Input } from "@material-tailwind/react";
import { useState } from "react";
import { useForm,Controller } from "react-hook-form";
import {BottomSheet}  from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'


export default function CityDialog(props){
    const {setOpen,openCityDialog} =props
    const [capital,setCapital]=useState('')
    return(
        <BottomSheet 
        open={openCityDialog}
        snapPoints={({ minHeight }) => minHeight}
        >
        My awesome
        </BottomSheet> 
        // <Dialog  open={
        //     openCityDialog
        //    }
        //    size={"xxl"}>
        //     <DialogBody as="div" className="mt-4 w-full overflow-y-hidden">
              
        //     </DialogBody>
        //     <DialogFooter>
        //     <Button
        //     variant="text"
        //     color="red"
        //     onClick={() => {
        //         setOpen(false)}
        //     }
        //     className="mr-1"
        //   >
        //     <span>Откажи{capital}</span>
        //   </Button>
        //   </DialogFooter>

        // </Dialog>
    )
}