import { Dialog,Button,DialogBody,DialogFooter,DialogHeader,Card,
CardBody, 
Typography} from "@material-tailwind/react";
import List from "./List";

export default function CartDialog(props){
    const {openCartDialog,setOpen,cart,next}=props
    return(
        <Dialog
        open={
         openCartDialog
        }
        size={"xxl"}
        
      >
        <DialogHeader>Вашата поръчка</DialogHeader>
        <DialogBody className="overflow-y-auto">
        
            <List cart={cart}/>
            <Typography>Общо:104 лв.</Typography>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="green"
            onClick={() => {
                setOpen(false)}
            }
            className="mr-1"
          >
            <span>Обратно към магазина</span>
          </Button>
          <Button
            variant="text"
            color="green"
            onClick={() => {
                setOpen(false);
                next(true);}
            }
            className="mr-1"
          >
            <span>Завърши</span>
          </Button>
          </DialogFooter>
        </Dialog>
    )

}