import { useForm,Controller } from "react-hook-form";
import { Dialog, DialogBody, DialogFooter, DialogHeader,Button,Input } from "@material-tailwind/react";



export default function FinishDialog(props){
     const {setOpen,openFinishDialog} =props
     const { register, handleSubmit,control,formState: { errors },reset } = useForm();
     const onSubmit=(data)=>{
        alert(JSON.stringify(data))
        reset()
        setOpen(false)


    }

    return(
      
        <Dialog
        open={
            openFinishDialog
           }
           size={"xxl"}>
            <DialogHeader>
                Адрес за доставка
            </DialogHeader>
            <DialogBody>
          
            <form className="grid justify-items-center gap-2 md:grid-cols-3 w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="">
            <Controller
        name="custname"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <Input  
          className="w-full"
          {...field}
          error={errors.custname ? true:false}  
          size="md" 
          label="Име на получател" 
            
          />

    )}/>
        </div>
        <div>
            <Controller
        name="custtel"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <Input  
          
          {...field}
          error={errors.custtel ? true:false} 
          size="md" 
          label="Телефон" 
            
           />

    )}/>
        </div>
        <div>
            <Controller
        name="city"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <Input  
          
          {...field}
          error={errors.addr ? true:false} 
          size="md" 
          label="Град" 
            
           />

    )}/>
        </div>
        <div>
            <Controller
        name="addr"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <Input  
          
          {...field}
          error={errors.addr ? true:false} 
          size="md" 
          label="Улица,номер" 
            
           />

    )}/>
        </div>
        
            </form>
            <div>
     
    </div>
           
            </DialogBody>
            <DialogFooter>
            <Button
            variant="text"
            color="red"
            onClick={() => {
                reset();setOpen(false)}
            }
            className="mr-1"
          >
            <span>Откажи</span>
          </Button>
          <Button
            type="submit"
            variant="gradient"
            color="green"
            onClick={ handleSubmit(onSubmit)}
          >
            <span>Поръчай</span>
          </Button>
            </DialogFooter>
        </Dialog>
       

    )
}